<template>
    <div id="home">
      <div>
        <div class="big_box">
          <div class="small_txt">
           深圳善艺澄科技有限公司
          </div>
          <div class="small_bigtxt">在线客服:</div>
          <div class="code_pic">
              <img src="../assets/firstpage/联系我们.png" alt="" class="image_code">
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .big_box {
    height: 10rem;
    background-image: url("../assets/firstpage/其他背景图2.png");
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    position: relative;
  }
  .small_txt {
    position: absolute;
    top: 2rem;
    font-size: 20px;
    left: 400px;
    color: #ffffff;
    box-sizing: border-box;
  }
  .small_bigtxt {
    position: absolute;
    box-sizing: border-box;
    left: 400px;
    top: 2.5rem;
    font-size: 16px;
    color: #4D4E4D;
  }
  .code_pic{
      position: absolute;
      box-sizing: border-box;
      top: 3rem;
      left: 400px;
      
  }
  .image_code{
      height: 150px;
  }
  </style>